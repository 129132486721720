<template>
  <!-- 下载发票 -->
  <el-dropdown v-if="isShow" class="line inline-flex-middle" @visible-change="visibleChangeHandler">
    <div>
      <ColorTextBtn :loading="fbaExpressCompanyLoading" size="mini">下载发票
      </ColorTextBtn
      >
      <i class="el-icon-arrow-down el-icon--right"></i>
    </div>

    <el-dropdown-menu
      style="width: 150px; text-align: center; max-height: 150px; overflow: auto"
      slot="dropdown"
    >
      <el-dropdown-item
        @click.native="downInvoiceHandler(id, label)"
        :key="id"
        v-for="{ companyName: label, id } in fbaExpressCompanyList"
      >
        <div style="max-width: 100%; text-align: left; display: inline-block; line-height: 1.5">
          {{ label }}
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { downloadImageByAixos } from '@/utils/download'
import { downloadInvoice } from '@/api/order/fbaOrderApi'
import { getExpressCompanyList } from '@/api/dicApi'
import { WAIT_UPLOAD_PACKING } from '@/utils/constant'
import { getOrderDownloadName } from '@/utils'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    idProp: {
      type: String,
      default: 'id'
    }
  },

  data() {
    return {
      loading: false,
      fbaExpressCompanyList: [],
      fbaExpressCompanyLoading: false
    }
  },

  computed: {
    isShow({ data }) {
      return data.orderStatus >= +WAIT_UPLOAD_PACKING
    }
  },

  methods: {
    async visibleChangeHandler(visiable) {
      if (!visiable) return
      this.fbaExpressCompanyList = []
      this.fbaExpressCompanyLoading = true
      const [err, data] = await this.getExpressCompanyList()
      this.fbaExpressCompanyList = data || []
      this.fbaExpressCompanyLoading = false
    },

    async getExpressCompanyList() {
      try {
        const cnName = this.data.expressShippingMethodCnName || this.data.expressCompanyName
        const { code, detail } = await getExpressCompanyList({
          cnName
        })
        return [!$SUC({ code }), detail]
      } catch {
        return [true, null]
      }
    },

    async downloadInvoice(fbaCompanyId) {
      try {
        const { id: orderId } = this.data
        const { code, detail } = await downloadInvoice({
          fbaCompanyId,
          orderId
        })
        return [!$SUC({ code }), detail]
      } catch {
        return [true, null]
      }
    },

    //下载发票
    async downInvoiceHandler(fbaCompanyId, label) {
      this.loading = true
      const [err, data] = await this.downloadInvoice(fbaCompanyId)
      this.loading = false
      if (err) return
      if (!data) {
        this.$message.error('发票地址不存在')
        return
      }
      downloadImageByAixos(data, getOrderDownloadName(this.data, `${label}发票`))
    }
  }
}
</script>

<style>
</style>