import request from '@/service/request'
import { getXMenuType, REQUEST_ALL_DATA } from '@/utils/constant'

// 增加字典列表
export function dicAddList(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionary/create',
    method: 'post',
    data
  })
}

// 根据id获取字典列表
export function getDicListById(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionary/getById',
    method: 'post',
    data
  })
}

// 修改字典列表
export function dicUpdateList(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionary/update',
    method: 'post',
    data
  })
}

// 删除字典列表
export function dicDeleteList(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionary/delete',
    method: 'post',
    data
  })
}

// 通过id查找详情字典信息
export function getDicItemById(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/getById',
    method: 'post',
    data
  })
}

// 字典详情创建
export function dicItemCreate(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/create',
    method: 'post',
    data
  })
}

// 字典明细修改
export function dicItemUpdate(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/update',
    method: 'post',
    data
  })
}

// 字典明细删除
export function dicItemdelete(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/delete',
    method: 'post',
    data
  })
}

// 获取字典列表
export function getDicList(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionary/list',
    method: 'post',
    data
  })
}

// 获取字典详情
export function getDetailList(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/list',
    method: 'post',
    data
  })
}

// 更新银行状态
export function updateBankStatus(data = {}) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/updateEnableStatus',
    method: 'post',
    data
  })
}

//获取fba公司列表
export function getExpressCompanyList(data, menuType = 'other') {
  return request({
    headers: getXMenuType(menuType),
    url: '/common/systemService/expressCompany/list/',
    method: 'post',
    data: {
      ...data,
      ...REQUEST_ALL_DATA
    }
  })
}
