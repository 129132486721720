import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//下载发票
export function downloadInvoice(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/orderService/order/downloadInvoice',
    method: 'post',
    data
  })
}
